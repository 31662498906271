import React from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './subscribed.scss';

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className={`${pageContext.slug} subscribed-wrapper`}>
            <div className="content-block" dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
            <div className="container subscribed-form">
                <div className="row">
                    <div className="col text-center justify-content-center">
                        <h1>Thank You</h1>
                        <p>for subscribing to Merit Mile marketing communications.</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)